/**
 * Infer the hub to use for the given group id
 */
export function inferHubLink(groupId: string | undefined) {
  if (!groupId) {
    return;
  }
  const [, arcCohortId] = groupId.split(/^arc-(.*)$/g);
  if (arcCohortId && arcCohortId !== "alumni") {
    return {
      hubLink: `/arc/${arcCohortId}`,
      tab: "updates",
    };
  }
  const [, baseCampId] = groupId.split(/^base-camp-(.*)$/g);
  if (baseCampId) {
    return {
      hubLink: `/base-camp/${baseCampId}`,
      tab: "feed",
    };
  }

  const [, scoutCampCohortId] = groupId.split(/^scout-camp-(.*)$/g);
  if (scoutCampCohortId) {
    // We don't usually make a group for scout camp, but this is what it'd be
    return {
      hubLink: `/scout-camp/${scoutCampCohortId}`,
    };
  }
}
