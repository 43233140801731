import { Comment, DealSharing, Group, Post, PostType, User } from "../models";
import { Notification, NotificationType } from "../models/notification-record";
import { escapeHTML } from "./escapeHTML";

type ActivityTextProps = {
  notification: Notification;
  userId: string;
  isDev: boolean;
  wantPlainText: boolean;
  classNames: {
    activityText?: string;
    senderName?: string;
    title?: string;
  };
  loadedMetadata: {
    post?: Post;
    postCreator?: User;
    comment?: Comment;
    group?: Group;
    sender?: User;
    dealSharing?: DealSharing;
  };
};

export function generateActivityText(
  notification: Notification,
  userId: string,
  isDev: boolean,
  classNames: {
    activityText?: string;
    senderName?: string;
    title?: string;
  },
  loadedMetadata: {
    post?: Post;
    postCreator?: User;
    comment?: Comment;
    group?: Group;
    sender?: User;
    dealSharing?: DealSharing;
  },
  wantPlainText = false,
): string | undefined {
  const text = activityTextSwitch({
    notification,
    userId,
    isDev,
    classNames,
    wantPlainText,
    loadedMetadata,
  });
  if (wantPlainText) return text;

  const classString = classNames.activityText
    ? `class="${classNames.activityText}"`
    : "";
  return text ? `<span ${classString}>${text}</span>` : "";
}

function activityTextSwitch(props: ActivityTextProps): string {
  const { notification } = props;
  switch (notification.data.type) {
    case NotificationType.adminPush:
    case NotificationType.postNoGroup:
    case NotificationType.generic:
    case NotificationType.chatMessage:
    case NotificationType.chatMessageReaction:
      return pushOnlyHtml(props);
    case NotificationType.postLiked:
      return activityPostLikedText(props);
    case NotificationType.postHelpful:
      return activityPostHelpfulText(props);
    case NotificationType.postEmpathize:
      return activityPostEmpathizeText(props);
    case NotificationType.postTagged:
      return activityPostTaggedText(props);
    case NotificationType.postReactedReply:
      return activityPostReactedReplyText(props);
    case NotificationType.postSubscribedReply:
      return activityPostSubscribedReplyText(props);
    case NotificationType.postPollConcluded:
    case NotificationType.postPollVoted:
      // Poll not supported yet
      return iOSOnlyHtml(props);
    case NotificationType.postReply:
      return activityPostReplyText(props);
    case NotificationType.commentTagged:
      // Can use the same component for posts and comments
      return activityPostTaggedText(props);
    case NotificationType.commentReply:
      return activityCommentReplyText(props);
    case NotificationType.commentLiked:
      return activityCommentLikedText(props);
    case NotificationType.commentHelpful:
      return activityCommentHelpfulText(props);
    case NotificationType.commentEmpathize:
      return activityCommentEmpathizeText(props);
    case NotificationType.commentReactedReply:
      return activityCommentReactedReplyText(props);
    case NotificationType.groupPost:
      return activityGroupPostText(props);
    case NotificationType.builderMentioned:
      return activityBuilderMentioned();
    case NotificationType.groupJoined:
      return activityGroupJoinedText(props);
    case NotificationType.groupAccepted:
    case NotificationType.groupInvite:
    case NotificationType.groupRequest:
      // Group not supported yet
      return iOSOnlyHtml(props);
    case NotificationType.memoApproved:
    case NotificationType.memoApprovedOther:
      return activityMemoApprovedText(props);
    case NotificationType.memoEdited:
      return activityMemoEditedText(props);
    case NotificationType.memoDueDiligenceCompleted:
      return activityMemoDueDiligenceCompletedText(props);
    case NotificationType.memoSubmitted:
      return activityMemoSubmittedText(props);
    case NotificationType.memoTagged:
      return activityMemoTaggedText(props);
    case NotificationType.memoCommentTagged:
      return activityMemoCommentTaggedText(props);
    case NotificationType.memoCommentReply:
      return activityMemoCommentReplyText(props);
    case NotificationType.memoFeedbackRequest:
      return activityMemoFeedbackRequest(props);
    case NotificationType.memoComment:
      return activityMemoCommentText(props);
  }
}

function activityPostLikedText(props: ActivityTextProps): string {
  const {
    loadedMetadata: { post, sender },
  } = props;
  if (!post || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const titleText = titleTextHtml(props);
  if (post.postType === PostType.poll) {
    return `${senderText} liked your poll ${titleText}`;
  }
  if (post.postType === PostType.welcomeVideo) {
    return `${senderText} liked ${titleText}`;
  }
  return `${senderText} liked your post ${titleText}`;
}

function activityPostHelpfulText(props: ActivityTextProps): string {
  const {
    loadedMetadata: { post, sender },
  } = props;
  if (!post || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const titleText = titleTextHtml(props);
  if (post.postType === PostType.poll) {
    return `${senderText} found your poll ${titleText} helpful`;
  }
  if (post.postType === PostType.welcomeVideo) {
    return `${senderText} found ${titleText} helpful`;
  }
  return `${senderText} found your post ${titleText} helpful`;
}

function activityPostEmpathizeText(props: ActivityTextProps): string {
  const {
    loadedMetadata: { post, sender },
  } = props;
  if (!post || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const titleText = titleTextHtml(props);
  if (post.postType === PostType.poll) {
    return `${senderText} marked your poll ${titleText} realtalk`;
  }
  if (post.postType === PostType.welcomeVideo) {
    return `${senderText} marked ${titleText} realtalk`;
  }
  return `${senderText} marked your post ${titleText} realtalk`;
}

function activityPostTaggedText(props: ActivityTextProps): string {
  const {
    loadedMetadata: { post, sender },
  } = props;
  if (!post || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const titleText = titleTextHtml(props);
  return `${senderText} mentioned you in ${titleText}`;
}

function activityPostReactedReplyText(props: ActivityTextProps): string {
  const {
    loadedMetadata: { post, sender },
  } = props;
  if (!post || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const titleText = titleTextHtml(props);
  return `${senderText} replied to ${titleText}`;
}

function activityPostSubscribedReplyText(props: ActivityTextProps): string {
  const {
    loadedMetadata: { post, sender },
  } = props;
  if (!post || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const titleText = titleTextHtml(props);
  return `${senderText} also replied to ${titleText}`;
}

function activityPostReplyText(props: ActivityTextProps): string {
  const {
    loadedMetadata: { post, sender },
  } = props;
  if (!post || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const titleText = titleTextHtml(props);
  if (post.postType === PostType.poll) {
    return `${senderText} replied to your ${titleText}`;
  }
  if (post.postType === PostType.welcomeVideo) {
    return `${senderText} replied to ${titleText}`;
  }
  return `${senderText} replied to your post ${titleText}`;
}

function activityCommentReplyText(props: ActivityTextProps): string {
  const {
    loadedMetadata: { comment, sender },
  } = props;
  if (!comment || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const titleText = titleTextHtml(props);
  return `${senderText} replied to you in ${titleText}`;
}

function activityCommentLikedText(props: ActivityTextProps): string {
  const {
    loadedMetadata: { comment, sender },
  } = props;
  if (!comment || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const titleText = titleTextHtml(props);
  return `${senderText} liked your reply in ${titleText}`;
}

function activityCommentHelpfulText(props: ActivityTextProps): string {
  const {
    loadedMetadata: { comment, sender },
  } = props;
  if (!comment || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const titleText = titleTextHtml(props);
  return `${senderText} found your reply in ${titleText} helpful`;
}

function activityCommentEmpathizeText(props: ActivityTextProps): string {
  const {
    loadedMetadata: { comment, sender },
  } = props;
  if (!comment || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const titleText = titleTextHtml(props);
  return `${senderText} marked your reply in ${titleText} realtalk`;
}

function activityCommentReactedReplyText(props: ActivityTextProps): string {
  const {
    loadedMetadata: { comment, sender },
  } = props;
  if (!comment || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const titleText = titleTextHtml(props);
  return `${senderText} also replied to ${titleText}`;
}

function activityGroupPostText(props: ActivityTextProps): string {
  const {
    loadedMetadata: { post, sender },
  } = props;
  if (!post || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const titleText = groupTextHtml(props);
  if (post.postType === PostType.poll) {
    return `${senderText} created a poll in ${titleText}`;
  }
  return `${senderText} posted in ${titleText}`;
}

function activityBuilderMentioned(): string {
  return `A founder posted on a topic you may be familiar with, check it out…`;
}

function activityGroupJoinedText(props: ActivityTextProps): string {
  const {
    loadedMetadata: { sender },
  } = props;
  if (!sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const titleText = groupTextHtml(props);
  return `${senderText} joined ${titleText}`;
}

function activityMemoApprovedText(props: ActivityTextProps): string {
  const {
    userId,
    loadedMetadata: { dealSharing, sender },
  } = props;
  if (!dealSharing || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const companyName = companyNameHtml(props);
  if (dealSharing.createdById === userId) {
    return `${senderText} approved your investment in ${companyName}. Scout Services will email you with next steps.`;
  }
  return `${senderText} approved the investment in ${companyName}`;
}

function activityMemoEditedText(props: ActivityTextProps): string {
  const {
    loadedMetadata: { dealSharing, sender },
  } = props;
  if (!dealSharing || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const companyName = companyNameHtml(props);
  return `${senderText} edited ${companyName}`;
}

function activityMemoDueDiligenceCompletedText(
  props: ActivityTextProps,
): string {
  const {
    loadedMetadata: { dealSharing, sender },
  } = props;
  if (!dealSharing || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const companyName = companyNameHtml(props);
  return `${senderText} completed due diligence for ${companyName}`;
}

function activityMemoSubmittedText(props: ActivityTextProps): string {
  const {
    loadedMetadata: { dealSharing, sender },
  } = props;
  if (!dealSharing || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const companyName = companyNameHtml(props);
  return `${senderText} requested approval for  ${companyName}`;
}

function activityMemoTaggedText(props: ActivityTextProps): string {
  const {
    loadedMetadata: { dealSharing, sender },
  } = props;
  if (!dealSharing || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const companyName = companyNameHtml(props);
  return `${senderText} mentioned you in ${companyName}`;
}

function activityMemoCommentTaggedText(props: ActivityTextProps): string {
  const {
    loadedMetadata: { dealSharing, sender },
  } = props;
  if (!dealSharing || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const companyName = companyNameHtml(props);
  return `${senderText} mentioned you in comment on ${companyName}`;
}

function activityMemoCommentReplyText(props: ActivityTextProps): string {
  const {
    loadedMetadata: { dealSharing, sender },
  } = props;
  if (!dealSharing || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const companyName = companyNameHtml(props);
  return `${senderText} replied to you on ${companyName}`;
}

function activityMemoFeedbackRequest(props: ActivityTextProps): string {
  const {
    loadedMetadata: { dealSharing, sender },
  } = props;
  if (!dealSharing || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const companyName = companyNameHtml(props);
  return `${senderText} requested feedback on ${companyName}`;
}

function activityMemoCommentText(props: ActivityTextProps): string {
  const {
    loadedMetadata: { dealSharing, sender },
  } = props;
  if (!dealSharing || !sender) {
    return "";
  }
  const senderText = senderTextHtml(props);
  const companyName = companyNameHtml(props);
  return `${senderText} commented on ${companyName}`;
}

////////////////////////////////////////////////////////////////////////////////

function titleTextHtml(props: ActivityTextProps): string {
  const {
    userId,
    classNames,
    loadedMetadata: { post, postCreator },
    wantPlainText,
  } = props;
  if (!post) {
    return "";
  }
  const classString = classNames.title ? `class="${classNames.title}"` : "";
  if (post) {
    if (post.postType === PostType.welcomeVideo) {
      return welcomePostTitle(userId, post, postCreator, wantPlainText);
    }
    if (wantPlainText) {
      return post.title;
    }
    return `<span ${classString}>${escapeHTML(post.title)}</span>`;
  }
  return "";
}

function groupTextHtml(props: ActivityTextProps): string {
  const {
    classNames,
    loadedMetadata: { group },
    wantPlainText,
  } = props;
  if (!group) {
    return "";
  }
  if (wantPlainText) return group.name;

  const classString = classNames.title ? `class="${classNames.title}"` : "";
  return `<span ${classString}>${escapeHTML(group.name)}</span>`;
}

function companyNameHtml(props: ActivityTextProps): string {
  const {
    classNames,
    loadedMetadata: { dealSharing },
    wantPlainText,
  } = props;
  if (!dealSharing) {
    return "";
  }
  if (wantPlainText) return dealSharing.meta.companyName;

  const classString = classNames.title ? `class="${classNames.title}"` : "";
  return `<span ${classString}>${escapeHTML(
    dealSharing.meta.companyName,
  )}</span>`;
}

function welcomePostTitle(
  userId: string,
  post?: Post,
  postCreator?: User,
  wantPlainText?: boolean,
): string {
  if (!post) {
    return "";
  }
  const isYourPost = !!userId && post.createdById === userId;
  if (isYourPost) {
    return `your welcome post`;
  }
  if (postCreator) {
    if (wantPlainText) {
      return `${postCreator.name}'s welcome post`;
    }
    return escapeHTML(`${postCreator.name}'s welcome post`);
  }
  return "";
}

function senderTextHtml(props: ActivityTextProps): string {
  const {
    classNames,
    loadedMetadata: { sender },
    wantPlainText,
  } = props;
  if (!sender) {
    return "";
  }
  if (wantPlainText) return sender.name;

  const classString = classNames.senderName
    ? `class="${classNames.senderName}"`
    : "";
  return `<span ${classString}>${escapeHTML(sender.name)}</span>`;
}

function iOSOnlyHtml(props: ActivityTextProps): string {
  return props.isDev ? "IOS Only" : "";
}

function pushOnlyHtml(props: ActivityTextProps): string {
  return props.isDev
    ? "Push notif only. Should not appear in notification stream"
    : "";
}
