import { ActivityCellList } from "../activity/ActivityCellList";
import { ArrowRightIcon } from "@sequoiacap/client-ui/icons";
import { Byline, List, Text } from "@sequoiacap/client-ui";
import NextLink from "~/components/link/NextLink";
import styles from "./ProfileMenuActivityList.module.scss";

const MAX_IN_HEADER = 3;

function ProfileMenuActivityList() {
  return (
    <ActivityCellList
      className={styles.activityList}
      max={MAX_IN_HEADER}
      noNotifications={
        <List.Item className={styles.noNotificationsCell}>
          <Text as="p" variant="body2Sans" color="textSubtle">
            No recent activity
          </Text>
        </List.Item>
      }
      loader={
        <>
          <List.Item className={styles.loadingCell}>
            <Byline loading />
          </List.Item>
          <List.Item className={styles.loadingCell}>
            <Byline loading />
          </List.Item>
          <List.Item className={styles.loadingCell}>
            <Byline loading />
          </List.Item>
        </>
      }
      seeMore={
        <Text
          as={NextLink}
          href="/activity"
          variant="label2"
          className={styles.moreActivityLink}
          skeletonProps={{ width: "80px", height: "100%" }}
        >
          <span>See more</span>
          <ArrowRightIcon width={16} height={16} />
        </Text>
      }
      activityCellProps={{
        className: styles.activityCell,
        bylineProps: { headerProps: { clamp: 3 }, size: "sm" },
        noBorder: true,
      }}
    />
  );
}

export default ProfileMenuActivityList;
