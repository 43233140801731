import { Avatar, AvatarProps } from "@sequoiacap/client-ui";
import { ResizedImageSize } from "@sequoiacap/shared/utils/resizeImageStoragePath";
import { isSequoiaUser } from "@sequoiacap/shared/utils/user";
import {
  useAPIGetUser,
  useAPIGetUserProfileImageUrl,
} from "~/network/user-api";
import classNames from "classnames";
import styles from "./ProfileImageView.module.scss";
import useLightUserInfo from "~/network/useLightUserInfo";

export type ProfileImageViewProps = AvatarProps & {
  userId?: string;
  hideBadge?: boolean;
  /**
   * If there's a textual version of the badge, use that instead of the image version.
   *
   * Not shown if hideBadge is true.
   *
   * e.g. [builder] under the profile image instead of the bulb icon in the bottom right.
   */
  useTextBadge?: boolean;
  imageSize?: ResizedImageSize;
};

function ProfileImageView({
  userId,
  hideBadge,
  useTextBadge,
  imageSize = ResizedImageSize.Square100,
  className,
  loading,
  ...props
}: ProfileImageViewProps): JSX.Element {
  const { userId: loggedInUserId, isWritableUser } = useLightUserInfo();
  const { data: user, loading: loadingUser } = useAPIGetUser(
    !hideBadge ? userId : undefined,
  );
  const { url, loading: loadingProfile } = useAPIGetUserProfileImageUrl(
    (isWritableUser || loggedInUserId !== userId) &&
      (user || loadingUser || hideBadge)
      ? userId
      : undefined,
    imageSize,
  );
  let imageBadge = undefined;
  if (!hideBadge) {
    if (isSequoiaUser(user)) {
      imageBadge = "/icons/sequoia-logo-circle.svg";
    } else if (user?.builderInfo && !useTextBadge) {
      imageBadge = "/icons/builder-logo-circle.svg";
    }
  }

  return (
    <Avatar
      className={classNames(
        styles.container,
        user?.archived && styles.archived,
        className,
      )}
      title={user?.name}
      src={url}
      imageBadge={imageBadge}
      loading={loading || loadingProfile || loadingUser}
      as="span"
      {...(user?.builderInfo &&
        useTextBadge && {
          hideBadge: true,
          textBadge: "Builder",
          textBadgeProps: {
            title:
              "Outlier engineering, GTM and product experts who generously share their advice",
            size: "xs",
            color: "darkOrange",
            textProps: { variant: "label3", static: true },
          },
        })}
      {...props}
    />
  );
}

export default ProfileImageView;
