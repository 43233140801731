import {
  AmpersandLinkedInExtensionRequestType,
  UserAudience,
} from "@sequoiacap/shared/models";
import { destroyNetworkCacheDb } from "~/worker-db";
import { getAuth } from "firebase/auth";
import { getFirstParam } from "@sequoiacap/shared/utils/getFirstParam";
import { sendLinkedInExtensionMessage } from "~/network/linkedin-extension-api";
import { useAPIGetMyExperienceHubs } from "~/network/experience-hub-api";
import { useAPIGetScoutInfo } from "~/network/memo-api";
import { useAPIGetServerProp } from "~/network/user-api";
import { useAPIListenNewNotifications } from "~/network/notification-api";
import { useAPIListenUnreadChats } from "~/network/chat-api";
import { useCallback, useEffect, useReducer } from "react";
import { useRouter } from "next/router";
import getConfig from "next/config";
import useLightUserInfo from "~/network/useLightUserInfo";
import useLocalStorage from "~/hooks/useLocalStorage";
import useServiceWorker from "~/hooks/useServiceWorker";
import useUserInfo from "~/network/useUserInfo";

const { publicRuntimeConfig } = getConfig();
const { IS_DEV_ENV } = publicRuntimeConfig;

export type HeaderContainerViewModel = {
  userId?: string;
  displayName?: string;
  email?: string;
  unreadMessageStr?: string;
  unreadActivityStr?: string;
  loading?: boolean;
  isWritableUser?: boolean;
  showBeta?: boolean;
  defaultBaseCampId?: string;
  showArcMenu?: boolean;
  defaultArcId?: string;
  showScoutMenu?: boolean;
  defaultScoutCampId?: string;
  error?: Error;
  signOut: () => Promise<void>;
  resetLastSeenActivityTime: () => void;
};

function reducer(
  currentState: HeaderContainerViewModel,
  action: Actions,
): HeaderContainerViewModel {
  switch (action.type) {
    case "setUnreadMessageStr": {
      if (action.payload === currentState.unreadMessageStr) {
        return currentState;
      }
      return {
        ...currentState,
        unreadMessageStr: action.payload,
      };
    }
    case "setUnreadActivityStr": {
      if (action.payload === currentState.unreadActivityStr) {
        return currentState;
      }
      return {
        ...currentState,
        unreadActivityStr: action.payload,
      };
    }
    case "setLoading": {
      if (currentState.loading) {
        return currentState;
      }
      return {
        ...currentState,
        loading: true,
      };
    }
    case "setError": {
      if (action.payload === currentState.error) {
        return currentState;
      }
      return {
        ...currentState,
        loading: false,
        error: action.payload,
      };
    }
    case "setUser": {
      if (isPayloadEqual(currentState, action.payload)) {
        return currentState;
      }
      return {
        ...currentState,
        ...action.payload,
        loading: false,
        error: undefined,
      };
    }
  }
}

type Actions =
  | { type: "setLoading" }
  | { type: "setError"; payload: Error }
  | { type: "setUnreadMessageStr"; payload?: string }
  | { type: "setUnreadActivityStr"; payload?: string }
  | { type: "setUser"; payload: SetUserPayload };

type SetUserPayload = {
  userId: string;
  displayName?: string;
  email?: string;
  isWritableUser?: boolean;
  defaultBaseCampId?: string;
  showArcMenu?: boolean;
  defaultArcId?: string;
  showScoutMenu?: boolean;
  defaultScoutCampId?: string;
  showBeta?: boolean;
};

export default function useHeaderContainer(): HeaderContainerViewModel {
  const {
    userId: loggedInUserId,
    displayName,
    email,
    loading,
    isWritableUser,
    isAdmin,
    specialGroupIds,
    userRecord: user,
    audiences,
  } = useUserInfo();
  const { chats } = useAPIListenUnreadChats();
  const { unreadNotificationCount, resetLastSeenActivityTime } =
    useLoadLastSeenActivityTime();
  const { data: scoutInfo } = useAPIGetScoutInfo(
    audiences?.includes(UserAudience.scout) ? loggedInUserId : null,
  );
  const { data: myExperienceHubs } =
    useAPIGetMyExperienceHubs(!!loggedInUserId);

  const { clearPrecacheData } = useServiceWorker();
  const { data: serverProp, loading: loadingServerProp } =
    useAPIGetServerProp(loggedInUserId);

  const loadingAll = loading || loadingServerProp;

  const [result, dispatch] = useReducer(reducer, {
    loading: true,
    showBeta: false,
    showScoutMenu: false,
    resetLastSeenActivityTime,
    signOut: async () => {
      window?.localStorage.clear();
      await getAuth().signOut();
      await sendLinkedInExtensionMessage({
        type: AmpersandLinkedInExtensionRequestType.SignOut,
      }).catch(console.error);
      await destroyNetworkCacheDb();
      await clearPrecacheData();
    },
  });

  useEffect(() => {
    if (loadingAll) {
      dispatch({
        type: "setLoading",
      });
    } else if (!loggedInUserId) {
      dispatch({
        type: "setError",
        payload: new Error("Missing profile id"),
      });
    }
  }, [loadingAll, loggedInUserId]);

  useEffect(() => {
    const unreadMessageCount = loggedInUserId
      ? chats
          ?.filter((chat) => chat.members[loggedInUserId].unreadCount ?? 0 > 0)
          .reduce(
            (sum, chat) =>
              sum + (chat.members[loggedInUserId].unreadCount ?? 0),
            0,
          ) || 0
      : 0;
    const unreadMessageStr = getCountMessage(unreadMessageCount);

    dispatch({
      type: "setUnreadMessageStr",
      payload: unreadMessageStr,
    });
  }, [chats, loggedInUserId]);

  useEffect(() => {
    const unreadActivityStr = getCountMessage(unreadNotificationCount);

    dispatch({
      type: "setUnreadActivityStr",
      payload: unreadActivityStr,
    });
  }, [unreadNotificationCount]);

  const defaultBaseCampId = myExperienceHubs?.defaultBaseCampId;
  const showArcMenu =
    !!myExperienceHubs?.defaultArcCohortId ||
    specialGroupIds?.includes("arc-alumni");
  const defaultArcId = myExperienceHubs?.defaultArcCohortId;
  const showScoutMenu = !!scoutInfo;
  const defaultScoutCampId = myExperienceHubs?.defaultScoutCampId;
  const showBeta =
    isWritableUser && (serverProp?.showBeta || isAdmin || IS_DEV_ENV);

  useEffect(() => {
    if (!loadingAll && loggedInUserId) {
      dispatch({
        type: "setUser",
        payload: {
          userId: loggedInUserId,
          displayName: (user?.name || displayName || email) ?? undefined,
          email: email ?? undefined,
          isWritableUser,
          defaultBaseCampId,
          showArcMenu,
          defaultArcId,
          showScoutMenu,
          defaultScoutCampId,
          showBeta,
        },
      });
    }
  }, [
    loadingAll,
    loggedInUserId,
    user?.name,
    displayName,
    email,
    isWritableUser,
    defaultBaseCampId,
    showArcMenu,
    defaultArcId,
    showScoutMenu,
    defaultScoutCampId,
    showBeta,
  ]);

  console.log(
    `useHeaderContainer userId=${result.userId} unreadMessageStr=${result.unreadMessageStr} loading=${result.loading} isWritableUser=${result.isWritableUser}`,
  );

  return result;
}

const useLoadLastSeenActivityTime = () => {
  const { userId: loggedInUserId } = useLightUserInfo();
  const { notifications, loading } = useAPIListenNewNotifications();
  const [lastSeenActivityTime, setLastSeenActivityTime] = useLocalStorage<
    number | undefined
  >("lastSeenActivityTime", undefined);
  const resetLastSeenActivityTime = useCallback(() => {
    setLastSeenActivityTime(Date.now());
  }, [setLastSeenActivityTime]);

  console.log(
    "useLoadLastSeenActivityTime/loading",
    loading,
    lastSeenActivityTime,
  );
  if (loading || !loggedInUserId) {
    return {
      unreadNotificationCount: 0,
      hasNewNotifications: false,
      resetLastSeenActivityTime,
    };
  }

  let hasNewNotifications = false;
  let unreadNotificationCount = 0;
  if (notifications && notifications.length > 0) {
    const latestNotification = notifications[0];
    if (
      lastSeenActivityTime &&
      latestNotification.createdAt.getTime() > lastSeenActivityTime
    ) {
      unreadNotificationCount = notifications.filter(
        (notification) => notification.state !== "read",
      ).length;
      hasNewNotifications = true;
    } else if (lastSeenActivityTime === undefined) {
      setLastSeenActivityTime(latestNotification.createdAt.getTime());
    }
  } else if (lastSeenActivityTime === undefined) {
    setLastSeenActivityTime(Date.now());
  }
  console.log(
    "useLoadLastSeenActivityTime/hasNewNotifications",
    hasNewNotifications,
  );
  return {
    unreadNotificationCount,
    hasNewNotifications,
    lastSeenActivityTime,
    resetLastSeenActivityTime,
  };
};

const isPayloadEqual = (
  currentState: HeaderContainerViewModel,
  action: SetUserPayload,
) => {
  return Object.keys(action).every(
    (key) =>
      currentState[key as keyof typeof currentState] ===
      action[key as keyof typeof action],
  );
};

const getCountMessage = (count: number) => {
  let unreadStr = undefined;
  if (count > 0) {
    if (count > 99) {
      unreadStr = "99+";
    } else {
      unreadStr = count.toString();
    }
  }

  return unreadStr;
};
