import { Button } from "@sequoiacap/client-ui";
import { MenuIcon } from "@sequoiacap/client-ui/icons";
import { useCallback } from "react";
import classNames from "classnames";
import styles from "./Burger.module.scss";

type BurgerProps = {
  className?: string;
  notification?: boolean;
  open: boolean;
  onToggle: (isOpen: boolean) => void;
};

function Burger({
  className,
  open,
  notification,
  onToggle,
}: BurgerProps): JSX.Element {
  const toggle = useCallback(() => {
    onToggle(!open);
  }, [open, onToggle]);

  return (
    <div
      className={classNames(styles.container, open && styles.open, className)}
    >
      <Button
        type="button"
        variant="plain"
        className={styles.button}
        contentClassName={styles.buttonContent}
        onClick={toggle}
        startIcon={<MenuIcon />}
      />
      <div
        className={classNames(
          styles.notificationDot,
          notification && styles.show,
        )}
      />
    </div>
  );
}

export default Burger;
