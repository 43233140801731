import { Group, GroupPrivacy } from "@sequoiacap/shared/models";
import { type ScoutInfo } from "~/network/memo-api";
import { inferHubLink } from "@sequoiacap/shared/utils/experienceHub";

export function getGroupVisibilityDetails(group?: Group | null) {
  let showLockIcon = false;
  let visibilityText = "";

  if (group?.privacy) {
    if (
      group.privacy === GroupPrivacy.public ||
      group.privacy === GroupPrivacy.opened
    ) {
      visibilityText = "Public Group";
    }
    if (
      group.privacy === GroupPrivacy.secret ||
      group.privacy === GroupPrivacy.searchable
    ) {
      visibilityText = "Private Group";
      showLockIcon = true;
    }
  }

  return {
    showLockIcon,
    visibilityText,
  };
}

/** NOTE: this deliberately includes `sequoia_scout` group */
export function canSubmitOpportunityFromHere(
  groupId: string | undefined,
): boolean {
  if (!groupId) {
    return false;
  }
  return groupId.endsWith("_scout");
}

/** NOTE: this deliberately excludes `sequoia_scout` group */
export function canSubmitOpportunityToHere(
  groupId: string | undefined,
): boolean {
  if (!groupId) {
    return false;
  }
  return groupId.endsWith("_scout") && groupId !== "sequoia_scout";
}

export function canShowSubmitOpportunity(
  scoutInfo?: ScoutInfo,
  groupId?: string,
): boolean {
  if (!scoutInfo || !groupId) {
    return false;
  }
  return (
    scoutInfo.scoutGroupId === groupId ||
    scoutInfo.privateScoutGroupId === groupId
  );
}

/** This generates the permalink for the group.
 *
 * It knows that some groups are redirected to a hub tab and will give that link instead of a typical group link.
 */
export function getGroupLink(groupId?: string) {
  if (typeof window === "undefined" || !groupId) {
    return "";
  }

  const { hubLink, tab } = inferHubLink(groupId) ?? {};
  if (hubLink) {
    if (
      window.location.pathname.startsWith(hubLink) &&
      (!tab || window.location.toString().includes(`tab=${tab}`))
    ) {
      return "";
    } else if (tab) {
      return `${hubLink}?tab=${tab}`;
    } else {
      return `${hubLink}`;
    }
  }

  return `/group?group=${groupId}`;
}
