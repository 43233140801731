import { createContext, useContext } from "react";
import useHeaderContainer, {
  HeaderContainerViewModel,
} from "~/components/header/useHeaderContainer";

const HeaderInfoContext = createContext<HeaderContainerViewModel | null>(null);

export default function useHeaderInfo(): HeaderContainerViewModel {
  const context = useContext(HeaderInfoContext);
  if (!context) {
    throw new Error("useHeaderInfo must be used within a HeaderInfoProvider");
  }
  return context;
}

export const HeaderInfoProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const vm = useHeaderContainer();

  return (
    <HeaderInfoContext.Provider value={vm}>
      {children}
    </HeaderInfoContext.Provider>
  );
};
