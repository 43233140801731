import { ActivityCell, ActivityCellState } from "./ActivityCell";
import { InfiniteScroll } from "~/components/infinite-scroll/InfiniteScroll";
import { List } from "@sequoiacap/client-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetNotifications } from "~/network/notification-api";

export type ActivityCellListProps = {
  /**
   * When max is set, we only ever load max+buffer amount and do not page in more, to avoid conflicts between truncation and InfiniteScroll
   *
   */
  max?: number;

  className?: string;
  activityCellProps?: Partial<React.ComponentProps<typeof ActivityCell>>;
  loader?: React.ReactNode;
  noNotifications?: React.ReactNode;
  seeMore?: React.ReactNode;
};

/**
 * This component a list of ActivityCell and abstracts away the list-cell coordination complexity.
 *
 * ActivityCell's VM handles the loading & hiding if there's error. This requires the ActivityCell elements to be mounted.
 * The list of cells also needs to know a bunch about the cells in aggregate so it can handke loading spinners, truncation, "see more", etc.
 * */
export function ActivityCellList(props: ActivityCellListProps): JSX.Element {
  const vm = useActivityCellList(props);
  return (
    <List className={props.className}>
      {vm.noNotifications && props.noNotifications}
      <InfiniteScroll
        loading={vm.loading}
        onLoadMore={vm.nextPage}
        hasNextPage={vm.hasMore}
        loader={props.loader}
      >
        {vm.notifications?.map((notification, idx) => (
          <ActivityCell
            {...props.activityCellProps}
            didLoad={(hasContent: boolean) => {
              vm.didLoadCell(idx, hasContent);
            }}
            key={`${notification.id}-${notification.threadId}`}
            notification={notification}
            hidden={vm.cellStates[idx]?.hidden}
          />
        ))}
      </InfiniteScroll>
      {vm.seeMore && props.seeMore}
    </List>
  );
}

const DEFAULT_PAGE_SIZE = 10;
const BUFFER = 10;
function useActivityCellList({ max = 0 }: { max?: number }) {
  const {
    data: notifications,
    loading: listLoading,
    hasMore: listHasMore,
    nextPage,
  } = useGetNotifications(Math.max(max + BUFFER, DEFAULT_PAGE_SIZE));

  const [cellStates, setCellStates] = useState<Array<ActivityCellState>>([]);
  useEffect(() => {
    if (!notifications) {
      if (cellStates.length) {
        setCellStates([]);
      }
      return;
    }
    if (notifications.length <= cellStates.length) {
      return;
    }
    // More notifications have loaded. Keep cellStates in sync with that
    for (let idx = cellStates.length; idx < notifications.length; idx++) {
      cellStates.push({
        hidden: true,
        loading: true,
      });
    }
    setCellStates([...cellStates]);
  }, [notifications, cellStates]);

  // Each individual cell loads the underlying entity. Until that cell is loaded, it has 0 height.
  // - load the list.
  // - mount an ActivityCell for each element of the list.
  // - each ActivityCell loads its underlying entity.
  // - each ActivityCell reports back to the list that it has loaded & whether it has content.
  // - list determines whether to show the cell or not, based on whether everything before it has loaded and whether it is one of the `max` visible cells.
  //
  // List loading: spinner, no empty
  // List done loading and some cells have loaded but none have content so far (e.g. some have error but some are still loading): spinner, no empty
  // List done loading and at least 1 cell is visible (has content, everything before it has loaded): no spinner, no empty, show that cell (maybe more)
  // List done loading and all cells have loaded but none have content: no spinner, empty
  // List done loading and at least `props.max` cells are visible: no spinner, no empty, show More button

  const someCellShown = useMemo(
    () => cellStates.some((v) => !v.hidden),
    [cellStates],
  );

  // Out of the current cellStates, are there more beyond `max`?
  const loadedCellsHasMore = useMemo(
    () => cellStates.filter((v) => v.hasContent).length > max,
    [cellStates, max],
  );

  // When we have a max, we attempt to load BUFFER extra elements, to avoid paginating.
  const isSomeCellLoading = !!cellStates?.some((v) => v.loading);
  const hasEnoughShown = cellStates.filter((v) => !v.hidden).length >= max;

  const loading = listLoading || (!hasEnoughShown && isSomeCellLoading);
  const hasMore = loading || max > 0 ? false : listHasMore;
  const seeMore = !loading && loadedCellsHasMore;
  const noNotifications =
    !listLoading && !isSomeCellLoading && !someCellShown && !hasMore;

  const didLoadCell = useCallback(
    (idx: number, hasContent: boolean) => {
      const cellState = cellStates[idx];
      if (!cellState) {
        return;
      }
      if (cellState.hasContent === hasContent && cellState.loading === false) {
        // noop
        return;
      }

      cellState.hasContent = hasContent;
      cellState.loading = false;
      recomputeHiddenOnCellStates(cellStates, max);
      setCellStates([...cellStates]);
    },
    [cellStates, max],
  );

  return {
    noNotifications,

    notifications,
    cellStates,
    didLoadCell,

    loading,
    nextPage,
    hasMore,
    seeMore,
  };
}

/**
 * Updates cellStates[].hidden, in place for every cellState.
 *
 * This should be called after the objects in cellStates[] have been modified but before doing setCellStates.
 */
function recomputeHiddenOnCellStates(
  cellStates: ActivityCellState[],
  max: number,
) {
  let numShown = 0;
  let everythingBeforeMeHasLoaded = true;
  for (const cellState of cellStates) {
    const cellCouldBeShown =
      everythingBeforeMeHasLoaded && cellState.hasContent && !cellState.loading;
    if (max && numShown >= max) {
      cellState.hidden = true;
    } else {
      cellState.hidden = !cellCouldBeShown;
    }
    numShown += cellCouldBeShown ? 1 : 0;

    if (cellState.loading) {
      everythingBeforeMeHasLoaded = false;
    }
  }
}
