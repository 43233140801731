import { Ref, forwardRef } from "react";
import { useRouter } from "next/router";
import Link, { LinkProps } from "next/link";

export type NextLinkProps = React.HTMLProps<HTMLAnchorElement> & {
  href?: LinkProps["href"];
  replace?: LinkProps["replace"];
  scroll?: LinkProps["scroll"];
};

const NextLink = forwardRef(
  (props: NextLinkProps, ref: Ref<HTMLAnchorElement>) => {
    const router = useRouter();
    const { href, children, replace, scroll, ...rest } = props;

    if (!href || href === router.asPath) {
      return (
        <span ref={ref} {...rest}>
          {children}
        </span>
      );
    }

    return (
      <Link href={href || ""} replace={replace} scroll={scroll} legacyBehavior>
        <a {...rest} ref={ref}>
          {children}
        </a>
      </Link>
    );
  },
);

NextLink.displayName = "NextLink";

export default NextLink;
