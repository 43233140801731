import {
  Notification,
  NotificationPerDay,
  notificationPerDayDataConverter,
} from "@sequoiacap/shared/models";
import { PaginatedResult } from "./firebase/types";
import { doc, setDoc } from "firebase/firestore";
import { fuego } from "./swr-firebase";
import { isValidNotification } from "@sequoiacap/shared/utils/notification";
import { useMemo } from "react";
import { usePaginate, useReadQuery } from "./firebase/FirebaseAPI";
import useLightUserInfo from "./useLightUserInfo";

export function useGetNotifications(limit = 10): PaginatedResult<Notification> {
  const { userId: loggedInUserId, isWritableUser } = useLightUserInfo();
  const {
    data: notificationPerDay,
    loading,
    hasMore,
    nextPage,
  } = usePaginate(
    isWritableUser ? "notification" : null,
    notificationPerDayDataConverter,
    limit,
    ["updated_at", "desc"],
    [["user_id", "==", loggedInUserId]],
  );
  const notifications = useParseNotificationPerDay(notificationPerDay);
  return {
    data: notifications,
    loading: loading ?? false,
    hasMore,
    nextPage,
  };
}

export function useAPIListenNewNotifications(): {
  notifications?: Notification[];
  loading: boolean;
  error?: Error;
} {
  const { userId: loggedInUserId } = useLightUserInfo();

  const {
    loading,
    error,
    data: notificationPerDay,
  } = useReadQuery(
    !!loggedInUserId ? "notification" : null,
    notificationPerDayDataConverter,
    1,
    ["updated_at", "desc"],
    [["user_id", "==", loggedInUserId]],
    { listen: true },
  );
  const notifications = useParseNotificationPerDay(notificationPerDay);

  return {
    loading,
    error,
    notifications,
  };
}

export async function markNotificationsAsRead(
  notifications: Notification[],
): Promise<void> {
  const updateMap = notifications.reduce(
    (acc: Record<string, Record<string, { state: string }>>, notification) => {
      if (notification.threadId && notification.state !== "read") {
        if (!acc[notification.threadId]) {
          acc[notification.threadId] = {};
        }
        acc[notification.threadId][notification.id] = {
          state: "read",
        };
      }
      return acc;
    },
    {},
  );
  await Promise.all(
    Object.entries(updateMap).map(async ([threadId, update]) => {
      console.log("markNotificationsAsRead", threadId, update);
      await setDoc(
        doc(fuego.db, `notification/${threadId}`),
        { notification: update },
        { merge: true },
      );
    }),
  );
  notifications.forEach((notification) => {
    notification.state = "read";
  });
}

function useParseNotificationPerDay(
  notificationPerDay?: NotificationPerDay[],
): Notification[] | undefined {
  const notifications = useMemo(() => {
    if (!notificationPerDay) {
      return undefined;
    }
    const result: Notification[] = [];
    notificationPerDay.forEach((day) => {
      result.push(
        ...Object.values(day.notification)
          .filter((item) => isValidNotification(item))
          .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()),
      );
    });
    return result;
  }, [notificationPerDay]);
  return notifications;
}
