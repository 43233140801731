import { Notification, NotificationType } from "../models/notification-record";

export function isValidNotification(notification: Notification): boolean {
  if (!notification.metadata.sender_id) {
    return false;
  }
  switch (notification.data.type) {
    case NotificationType.postNoGroup:
    case NotificationType.generic:
    case NotificationType.chatMessage:
    case NotificationType.chatMessageReaction:
      // Don't show up in activity tab. NOTE: this won't affect the push notification
      return false;
    case NotificationType.postLiked:
    case NotificationType.postTagged:
    case NotificationType.postHelpful:
    case NotificationType.postEmpathize:
    case NotificationType.postPollConcluded:
      return !!notification.metadata.post_id;
    case NotificationType.postPollVoted:
      // Poll not supported yet
      return false;
    // if let post = metadata.post,
    //    post.poll != nil,
    //    metadata.pollCount != nil,
    //    metadata.pollHour != nil {
    //     return true
    // }
    case NotificationType.postReply:
    case NotificationType.commentTagged:
    case NotificationType.commentReply:
    case NotificationType.commentLiked:
    case NotificationType.commentHelpful:
    case NotificationType.commentEmpathize:
    case NotificationType.postSubscribedReply:
    case NotificationType.postReactedReply:
    case NotificationType.commentReactedReply:
      return !!(
        notification.metadata.post_id && notification.metadata.comment_id
      );
    case NotificationType.groupInvite:
    case NotificationType.groupAccepted:
    case NotificationType.groupJoined:
    case NotificationType.groupRequest:
      // Group not supported yet
      return false;
    case NotificationType.builderMentioned:
      return !!notification.metadata.post_id;

    // if metadata.group != nil {
    //     return true
    // }
    case NotificationType.groupPost:
      return !!(
        notification.metadata.post_id && notification.metadata.group_id
      );
    case NotificationType.memoApproved:
    case NotificationType.memoApprovedOther:
    case NotificationType.memoSubmitted:
    case NotificationType.memoFeedbackRequest:
    case NotificationType.memoEdited:
    case NotificationType.memoDueDiligenceCompleted:
    case NotificationType.memoComment:
    case NotificationType.memoCommentTagged:
    case NotificationType.memoCommentReply:
    case NotificationType.memoTagged:
      return !!(
        notification.metadata.post_id && notification.metadata.deal_sharing_id
      );
    case NotificationType.adminPush:
      return false;
  }
}

export function isImmediateEmailNotification(
  notifcationType: NotificationType,
): boolean {
  switch (notifcationType) {
    case NotificationType.memoApproved:
    case NotificationType.memoSubmitted:
    case NotificationType.memoFeedbackRequest:
    case NotificationType.memoEdited:
    case NotificationType.memoComment:
    case NotificationType.memoCommentTagged:
    case NotificationType.memoCommentReply:
    case NotificationType.memoTagged:
      return true;
    default:
      return false;
  }
}

export function isEmailWorthyNotification(notification: Notification): boolean {
  switch (notification.data.type) {
    case NotificationType.postNoGroup:
    case NotificationType.generic:
      return false;
    case NotificationType.chatMessage:
    case NotificationType.chatMessageReaction:
      // Unread chat email is not triggered by notification
      return false;
    case NotificationType.postTagged:
      return true;
    case NotificationType.postLiked:
    case NotificationType.postHelpful:
    case NotificationType.postEmpathize:
      return false;
    case NotificationType.postPollConcluded:
    case NotificationType.postPollVoted:
      // Poll not supported yet
      return false;
    case NotificationType.postReply:
    case NotificationType.commentTagged:
    case NotificationType.commentReply:
      return true;
    case NotificationType.commentLiked:
    case NotificationType.commentHelpful:
    case NotificationType.commentEmpathize:
      return false;
    case NotificationType.postSubscribedReply:
    case NotificationType.postReactedReply:
    case NotificationType.commentReactedReply:
      return true;
    case NotificationType.builderMentioned:
      return true;
    case NotificationType.groupInvite:
    case NotificationType.groupAccepted:
    case NotificationType.groupJoined:
    case NotificationType.groupRequest:
      // Group not supported yet
      return false;
    case NotificationType.groupPost:
      return true;

    // memo has special email
    case NotificationType.memoApproved:
    case NotificationType.memoSubmitted:
    case NotificationType.memoFeedbackRequest:
    case NotificationType.memoEdited:
    case NotificationType.memoComment:
    case NotificationType.memoCommentTagged:
    case NotificationType.memoCommentReply:
    case NotificationType.memoTagged:
      return false;

    case NotificationType.memoDueDiligenceCompleted:
    case NotificationType.memoApprovedOther:
      return true;

    case NotificationType.adminPush:
      return false;
  }
}

export function getActionLink(notification: Notification): string | undefined {
  switch (notification.data.type) {
    case NotificationType.postNoGroup:
    case NotificationType.generic:
    case NotificationType.chatMessage:
    case NotificationType.chatMessageReaction:
      return;
    case NotificationType.postLiked:
    case NotificationType.postTagged:
    case NotificationType.postHelpful:
    case NotificationType.postEmpathize:
    case NotificationType.postPollConcluded:
    case NotificationType.postPollVoted:
    case NotificationType.memoApproved:
    case NotificationType.memoApprovedOther:
    case NotificationType.memoEdited:
    case NotificationType.memoDueDiligenceCompleted:
    case NotificationType.memoSubmitted:
    case NotificationType.memoFeedbackRequest:
    case NotificationType.memoComment:
    case NotificationType.memoCommentTagged:
    case NotificationType.memoCommentReply:
    case NotificationType.memoTagged:
      if (notification.metadata.post_id) {
        return `/post?post=${notification.metadata.post_id}`;
      }
      return;
    case NotificationType.builderMentioned:
      if (notification.metadata.post_id) {
        if (notification.metadata.comment_id) {
          return `/comment?comment=${notification.metadata.comment_id}&post=${notification.metadata.post_id}`;
        }
        return `/post?post=${notification.metadata.post_id}`;
      }
      return;
    case NotificationType.postReply:
    case NotificationType.postSubscribedReply:
    case NotificationType.postReactedReply:
    case NotificationType.commentTagged:
    case NotificationType.commentReply:
    case NotificationType.commentLiked:
    case NotificationType.commentHelpful:
    case NotificationType.commentEmpathize:
    case NotificationType.commentReactedReply:
      if (notification.metadata.post_id && notification.metadata.comment_id) {
        return `/comment?comment=${notification.metadata.comment_id}&post=${notification.metadata.post_id}`;
      }
      return;
    case NotificationType.groupAccepted:
    case NotificationType.groupInvite:
    case NotificationType.groupJoined:
    case NotificationType.groupRequest:
      if (notification.metadata.group_id) {
        return `/group?group=${notification.metadata.group_id}`;
      }
      return;
    case NotificationType.groupPost:
      if (notification.metadata.post_id && notification.metadata.group_id) {
        return `/post?post=${notification.metadata.post_id}`;
      }
      return;
    case NotificationType.adminPush:
      return;
  }
}
