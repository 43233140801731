import { StorageTimestamp } from "@sequoiacap/shared/models";
import { useEffect, useState } from "react";

/*
- Just now
- 19 m
- 5 h
- 14 d
- Jan 4
- 1/4/2020

NOTE: future times will still say "just now" */
export function useAgoDateShort(date?: StorageTimestamp): string | undefined {
  const [result, setResult] = useState<string | undefined>(agoDateShort(date));
  useEffect(() => {
    setResult(agoDateShort(date));
    const interval = setInterval(() => {
      setResult(agoDateShort(date));
    }, 60000);
    return () => clearInterval(interval);
  }, [date]);

  return result;
}

function agoDateShort(date?: StorageTimestamp): string | undefined {
  if (!date) {
    return undefined;
  }
  const currentTime = Date.now();
  const currentDate = new Date(currentTime);
  const incomingDate = new Date(date.getTime());

  const secondsBetween = (currentTime - date.getTime()) / 1000;
  if (secondsBetween < 60) {
    return "Just Now";
  } else if (secondsBetween < 60 * 60) {
    return `${Math.floor(secondsBetween / 60)}m`;
  } else if (secondsBetween < 24 * 60 * 60) {
    return `${Math.floor(secondsBetween / (60 * 60))}h`;
  } else if (secondsBetween < 15 * 24 * 60 * 60) {
    return `${Math.floor(secondsBetween / (24 * 60 * 60))}d`;
  } else if (
    secondsBetween < 90 * 24 * 60 * 60 ||
    currentDate.getFullYear() === incomingDate.getFullYear()
  ) {
    const preferredLocale = navigator.languages[0] || "en-US";
    return incomingDate.toLocaleString(preferredLocale, {
      day: "numeric", // 31
      month: "short", // Mar
    });
  } else {
    const preferredLocale = navigator.languages[0] || "en-US";
    return incomingDate.toLocaleString(preferredLocale, {
      day: "numeric", // 31
      month: "numeric", // 2
      year: "numeric", // 2020
    });
  }
}
