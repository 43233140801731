import {
  Comment,
  Group,
  Notification,
  Post,
  User,
} from "@sequoiacap/shared/models";
import { getActionLink } from "@sequoiacap/shared/utils/notification";
import { useAPIGetComment } from "~/network/comment-api";
import { useAPIGetDeal } from "~/network/memo-api";
import { useAPIGetGroup } from "~/network/group-api";
import { useAPIGetPost } from "~/network/post-api";
import { useAPIGetUser } from "~/network/user-api";
import { useAgoDateShort } from "~/hooks/useAgoDateShort";

export type ActivityCellViewModel = {
  loading: boolean;
  error: Error | undefined;
  loadedMetadata: {
    post?: Post;
    postCreator?: User;
    comment?: Comment;
    group?: Group;
    sender?: User;
  };
  /** Short string like 4d */
  dateTimeString?: string;
  /** Full string like `Fri Nov 12, 2022 3:52pm` */
  dateTimeStringFull?: string;
  hasBeenRead?: boolean;
  link?: string;
};

function useActivityCell(notification: Notification): ActivityCellViewModel {
  const {
    data: post,
    loading: postLoading,
    error: postError,
  } = useAPIGetPost(notification.metadata.post_id ?? null);
  const {
    data: sender,
    loading: senderLoading,
    error: senderError,
  } = useAPIGetUser(notification.metadata.sender_id ?? null);
  const {
    data: group,
    loading: groupLoading,
    error: groupError,
  } = useAPIGetGroup(notification.metadata.group_id ?? null);
  const {
    data: comment,
    loading: commentLoading,
    error: commentError,
  } = useAPIGetComment(
    notification.metadata.post_id ?? null,
    notification.metadata.comment_id ?? null,
  );
  const {
    data: postCreator,
    loading: postCreatorLoading,
    error: postCreatorError,
  } = useAPIGetUser(post?.createdById ?? null);
  const {
    data: dealSharing,
    loading: dealSharingLoading,
    error: dealSharingError,
  } = useAPIGetDeal(notification.metadata.deal_sharing_id ?? null);

  const dateTimeString = useAgoDateShort(notification.createdAt);
  const dateTimeStringFull = notification.createdAt?.toLocaleString();

  const error =
    postError ||
    senderError ||
    groupError ||
    commentError ||
    postCreatorError ||
    dealSharingError;
  const loading =
    postLoading ||
    senderLoading ||
    groupLoading ||
    commentLoading ||
    postCreatorLoading ||
    dealSharingLoading;
  const loadedMetadata = {
    post,
    postCreator,
    comment,
    group,
    sender,
    dealSharing,
  };

  return {
    error,
    loading,
    dateTimeString,
    dateTimeStringFull,
    loadedMetadata: !loading ? loadedMetadata : {},
    hasBeenRead: notification.state === "read",
    link: notification.data?.link || getActionLink(notification),
  };
}

export default useActivityCell;
