import { ActivityCellViewModel } from "./useActivityCell";
import { Notification } from "@sequoiacap/shared/models";
import { generateActivityText } from "@sequoiacap/shared/utils/activityText";
import { isDev } from "~/network/swr-firebase/helpers/is-dev";
import { useMemo } from "react";
import parse from "html-react-parser";
import styles from "./ActivityCell.module.scss";
import useLightUserInfo from "~/network/useLightUserInfo";

export type ActivityTextProps = {
  notification: Notification;
  vm: ActivityCellViewModel;
};

function ActivityText({
  notification,
  vm,
}: ActivityTextProps): JSX.Element | null {
  const { userId } = useLightUserInfo();

  const activityText =
    !!userId &&
    generateActivityText(
      notification,
      userId,
      isDev,
      {
        activityText: styles.activityText,
        senderName: styles.senderName,
        title: styles.title,
      },
      vm.loadedMetadata,
    );

  const parsed = useMemo(
    () => activityText && parse(activityText),
    [activityText],
  );

  if (!activityText) {
    return null;
  }

  return <>{parsed}</>;
}

export default ActivityText;
